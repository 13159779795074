import React from "react"
import { CenterContent, SubAndHeadline } from "../components/_index"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import RichTextRenderer from "../components/RichTextRenderer"
import SiteMetadata from "../components/SiteMetaData"
import Layout from "../components/Layout"

const Privacy = ({
  data: {
    content: {
      title,
      childContentfulPrivacyDescriptionRichTextNode: { json: richText },
    },
  },
}) => {
  return (
    <Layout>
      <SiteMetadata title={title} description={title} />
      <CenterContent>
        <Grid container direction="column" spacing={3} align={"center"}>
          <SubAndHeadline title={title} />
          <Grid container item justify="center">
            <Grid item xs={11} sm={8} md={6}>
              <Typography
                component={"span"}
                variant={"body1"}
                style={{ whiteSpace: "break-spaces" }}
              >
                <RichTextRenderer richTextJson={richText} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CenterContent>
    </Layout>
  )
}

Privacy.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query {
    content: contentfulPrivacy {
      title
      childContentfulPrivacyDescriptionRichTextNode {
        json
      }
    }
  }
`
export default Privacy
